<template>
  <div class="animated fadeIn">
    <b-card>
      <div class="d-flex justify-content-end">
        <router-link
          v-if="hasPermission('edit/syarat-dan-ketentuan')"
          :to="`/${config.uri}/edit`"
          title="Edit"
          class="floating-btn btn btn-primary display-table-cell pull-right rounded order-0 order-md-1 mb-1 mb-md-0"
        >
          <i class="fa fa-plus" /> Edit Syarat dan Ketentuan
        </router-link>
        <!-- <button
          class="floating-btn btn btn-primary display-table-cell pull-right rounded order-0 order-md-1 mb-1 mb-md-0"
        /> -->
      </div>
      <div
        v-if="content"
        class="d-flex flex-column p-sm-5 mt-4 mt-sm-0"
      >
        <div
          class="h1 text-center mb-5"
          v-html="content.title"
        />
        <div
          v-html="content.content"
        />
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
// import table from '@/utils/table.js'
import api from '@/utils/api'

export default {
  name: 'ListPrivacyPolicy',
  metaInfo: {
    title: 'List Privacy Policy',
  },
  components: {
    BCard,
  },
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: api.pageContent,
        rows: [],
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        additional_params: {
          type: 1,
        },
      },
    }
  },
  computed: {
    content() {
      return this.config.rows[0]
    },
  },
  created() {
    const _ = this
    _.get()
  },
  methods: {
    get() {
      this.$table.get(this.config)
    },
  },
}
</script>

<style scoped>
@media (max-width: 991.98px) {
  .floating-btn {
    position: fixed !important;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5%;
    width: max-content;
  }
}
</style>
